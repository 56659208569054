import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';


import { BaseService } from './base.service';
import { ValidationResult } from './../_models/validation-result';
import { InfoPgtoAdicional } from '../_models/InfoPgtoAdicional';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { Operacao } from 'app/_enums/operacao';
import { MessageService } from './message.service';


@Injectable({
    providedIn: 'root'
})
export class InfoPgtoAdicionalService {

    constructor(private baseService: BaseService,
      private _msgService: MessageService) { }

    SalvarInfoPgtoAdicional(infoPgtoAdicional: InfoPgtoAdicional): Observable<ValidationResult> {
        if (!infoPgtoAdicional.idInfoPgtoAdicional || infoPgtoAdicional.idInfoPgtoAdicional == 0)
            return this.baseService.Post('infopgtoadicional/salvarinfopgtoadicional', infoPgtoAdicional).pipe(
                map((response: ValidationResult) => {
                    let retorno: ValidationResult = response;
                    return retorno;
                }),catchError(e => {
                    return this.baseService.handleError(e);
                }),);
        else
            return this.baseService.Put('infopgtoadicional/' + infoPgtoAdicional.idInfoPgtoAdicional, infoPgtoAdicional).pipe(
                map((response: ValidationResult) => {
                    let retorno: ValidationResult = response;
                    return retorno;
                }),catchError(e => {
                    return this.baseService.handleError(e);
                }),);
    }


    getInfoPgoAdicional(id: number): Observable<InfoPgtoAdicional> {
      return this.baseService.Get('GetByNumBooking/' + id).pipe(
          map((response: ValidationResult) => {
              let retorno: ValidationResult = response;

              if (retorno.IsValid)
                  return retorno.Data as InfoPgtoAdicional;
              else
                  return null;
          }),catchError(e => {
              return this.baseService.handleError(e);
          }),);
  }

  getInfoPgtoAdicionalByNumBooking(numBooking: string): Observable<InfoPgtoAdicional> {

        return this.baseService.Get('infopgtoadicional/' + numBooking).pipe(
            map((response: ValidationResult) => {

                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as InfoPgtoAdicional;
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }


    getListaInfoPgtoAdicional(startDate: String, endDate: String): Observable<InfoPgtoAdicional[]> {
    return this.baseService.Get('infopgtoadicional/listainfopgtoadicionalbyperiodo?startDate=' + startDate + '&endDate=' + endDate).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Info Pagto Adicional', retorno.Erros, 'error', LogFrontFuncionalidade.InfoPgtoAdicional, Operacao.Consulta, null);
        }

        return retorno.Data as InfoPgtoAdicional[];
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }


  }
