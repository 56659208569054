import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { element } from 'protractor';
import { DomSanitizer } from '@angular/platform-browser';

import { Porto } from './../../../_models/porto';
import { Classe } from './../../../_models/classe';
import { Cotacao } from './../../../_models/cotacao';
import { CargaIMO } from './../../../_models/cargaIMO';
import { Municipio } from './../../../_models/municipio';
import { Mercadoria } from './../../../_models/mercadoria';
import { TipoEmbalagem } from './../../../_models/tipoEmbalagem';
import { Periodicidade } from './../../../_models/periodicidade';
import { TipoContainer } from './../../../_models/tipo-container';
import { CotacaoRequest } from 'app/_models/cotacao-request';
import { VeiculoEspecial } from './../../../_models/veiculoEspecial';
import { TipoPagadorFrete } from './../../../_models/tipoPagadorFrete';
import { ValidationResult } from './../../../_models/validation-result';
import { TipoOperacaoComercial } from './../../../_models/tipoOperacaoComercial';

import { AppService } from './../../../_services/app.service';
import { DataService } from './../../../_services/data.service';
import { SharedService } from './../../../_services/shared.service';
import { CotacaoService } from './../../../_services/cotacao.service';
import { MessageService } from './../../../_services/message.service';
import { MunicipioService } from './../../../_services/municipio.service';

import { BaseComponent } from './../../../shared/base.component';
import { MessageComponent } from './../../../shared/message/message.component';
import { CnpjDropdownComponent } from './../../../shared/cnpj-dropdown/cnpj-dropdown.component';
import { AlteracoesArquivoUpload } from '../../../_models/alteracoes-arquivo-upload';
import { UploadArquivoService } from '../../../_services/upload-arquivo.service';
import { UploadArquivosComponent } from 'app/shared/uploadarquivos/uploadarquivos.component';
import { AnalyticsService } from 'app/_services/analytics.service';
import { AnalyticsTela } from 'app/_enums/analytics-tela';
import { AnalyticsOperacao } from 'app/_enums/analytics-operacao';
import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Component({
  selector: 'app-solicitar-cotacao',
  templateUrl: './solicitar-cotacao.component.html',
  styleUrls: ['./solicitar-cotacao.component.scss']
})
export class SolicitarCotacaoComponent extends BaseComponent implements OnInit {


  cotacao: Cotacao = new Cotacao();
  titulo: string;
  novaCotacao: boolean = true;
  duplicar: boolean = false;
  loading: boolean = false;
  uploading: boolean = false;
  loadingSolicitar: boolean = false;
  loadingMunicipios: boolean = false;
  loadingMercadorias: boolean = false;
  loadingCargaPerigosa: boolean = false;
  loadingContainers: boolean = false;
  flagOutrosProdutos: boolean = false;
  bloqueiaCargaRefrigerada: boolean = true;
  bloqueiaCargaExcesso: boolean = true;
  bloqueiaOpenTop: boolean = true;
  bloqueiaModalPorta: boolean = false;
  result: ValidationResult;
  error = '';
  currentStep: number = 1;
  tipoContainers: TipoContainer[] = [];
  cargasPerigosas: CargaIMO[];
  cargasPerigosasByClass: CargaIMO[] = [];
  classes: Classe[] = [];
  mercadorias: Mercadoria[];
  portos: Porto[];
  municipios: Municipio[] = [];
  municipioColeta: Municipio;
  municipioEntrega: Municipio;
  inscricaoGet: Subscription;
  idContainerSelecionado: string = '';
  descTipoPagadorFrete: string = '';
  embalagemSelecionada: string = '';
  veiculoOrigemSelecionado: string = '';
  veiculoDestinoSelecionado: string = '';

  veiculosEspeciais: VeiculoEspecial[] = new Array();
  tipoEmbalagens: TipoEmbalagem[] = new Array();
  tiposOperacaoComercial: TipoOperacaoComercial[] = new Array();
  tiposPagadorFrete: TipoPagadorFrete[] = new Array();
  periodicidades: Periodicidade[] = new Array();

  @ViewChild(UploadArquivosComponent, {static: true}) uploadarquivos: UploadArquivosComponent;
  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
  @ViewChild(CnpjDropdownComponent, {static: true}) cnpjdropdown: CnpjDropdownComponent;
  @ViewChild('topo', {static: true}) private topo: ElementRef;

  constructor(
    private _cotacaoService: CotacaoService,
    private _msgService: MessageService,
    private _router: Router,
    private _sharedService: SharedService,
    private _route: ActivatedRoute,
    private _appService: AppService,
    private _dataService: DataService,
    private _municipioService: MunicipioService,
    private _uploadService: UploadArquivoService,
    private _sanitizer: DomSanitizer,
    private _analyticsService: AnalyticsService) {

    super();

    this._msgService.emitirMensagem.subscribe(
      msg => {
        if (msg.mensagem instanceof Array)
          this.mensagem.mostrarMensagemArray(msg.tipo, msg.mensagem);
        else
          this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );
  }

  autocompleListFormatter = (data: any) => {
    let html = `<span>${data.Descricao}/${data.UF}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
    //return html;
  }

  ngOnInit() {

    this.cnpjdropdown.SelecionaMultiplos = false;
    this.cnpjdropdown.modoEdicao = true;

    // Se receber a cotação como parâmetro, exibe dados da mesma
    this.inscricaoGet = this._route.params.subscribe(
      (params: any) => {

        var consulta: CotacaoRequest = new CotacaoRequest();
        consulta.CotacaoID = params['id'];
        consulta.IdTrecho = params['idTrecho'];
        consulta.UsuarioID = this._appService.UsuarioLogado.usuarioIDeCargo;

        this.duplicar = params['duplicar'];

        this.novaCotacao = !consulta.CotacaoID || this.duplicar;
        this.titulo = this.novaCotacao ? 'Nova Cotação' : 'Consultar Cotação';

        if (consulta.CotacaoID) {
          this.carregaCotacaoPorId(consulta);
        }

      }
    );

    if (this.novaCotacao) {
      this.cotacao.idFamiliaProduto = null;
      this.ObterMercadorias();
      this.ObterPortos();
      this.ObterMunicipios();
      this.ObterDataFields();
    }
  }

  ObterDataFields() {
    this._dataService.getVeiculosEspeciais()
      .subscribe(lista => {
        this.veiculosEspeciais = lista;
      });

    this._dataService.getTipoEmbalagens()
      .subscribe(lista => {
        this.tipoEmbalagens = lista;
      });

    this._dataService.getTiposOperacaoComercial()
    .subscribe(lista => {
      this.tiposOperacaoComercial = lista;
    });

    this._dataService.getTiposPagadorFrete()
      .subscribe(lista => {
        this.tiposPagadorFrete = lista;
      });

    this._dataService.getPeriodicidades()
      .subscribe(lista => {
        this.periodicidades = lista;
      });

    this._dataService.getResponsaveisDesova()
      .subscribe(lista => {
        this.cotacao.responsaveisDesova = lista;
      });

    this._dataService.getResponsaveisOva()
      .subscribe(lista => {
        this.cotacao.responsaveisOva = lista;
      });
  }

  private CorrigeNumber(valor: any): number {
    if (!valor)
      return valor;

    var num = valor.toString();
    num = this._sharedService.replaceAll(valor, '.', '');
    num = num.replace(',', '.');
    return Number(num);
  }

  get permiteUpload(): boolean{
    return this.cotacao.cargaOver && (this.novaCotacao || (!this.novaCotacao && this.cotacao.statusCotacao == 'Em processo de cotação'));
  }

  carregaCotacaoPorId(consulta: CotacaoRequest) {
    this.loading = true;
    this._cotacaoService.getCotacao(consulta)
      .subscribe(cotacaoRetorno => {
        this._sharedService.copyProperties(cotacaoRetorno, this.cotacao);
        var tp: TipoContainer = new TipoContainer;
        this._sharedService.copyProperties(cotacaoRetorno.tipoDeContainer, tp);
        this.cotacao.tipoDeContainer = tp;

        this.cotacao.valMercadoria = this.CorrigeNumber(cotacaoRetorno.valMercadoria);
        this.cotacao.pesoContainer = this.CorrigeNumber(cotacaoRetorno.pesoContainer);

        if (cotacaoRetorno.idFamiliaProduto == null) {
          this.cotacao.idFamiliaProduto = 0;
          this.flagOutrosProdutos = true;
        }

        if (!this.tipoEmbalagens.find(e => e.codigo == cotacaoRetorno.tipoEmbalagemCarga))
          this.embalagemSelecionada = 'Outros';
        else
          this.embalagemSelecionada = cotacaoRetorno.tipoEmbalagemCarga;


        if (!this.veiculosEspeciais.find(e => e.codigo == cotacaoRetorno.VeiculoEspecialOri))
          this.veiculoOrigemSelecionado = 'Outros';
        else
          this.veiculoOrigemSelecionado = cotacaoRetorno.VeiculoEspecialOri;

        if (!this.veiculosEspeciais.find(e => e.codigo == cotacaoRetorno.VeiculoEspecialDes))
          this.veiculoDestinoSelecionado = 'Outros';
        else
          this.veiculoDestinoSelecionado = cotacaoRetorno.VeiculoEspecialDes;

        //this.cotacao.IdUsuario = this.appService.UsuarioLogado.UsuarioID;
        //this.onChangeCampo('embalagem', this.cotacao.tipoEmbalagemCarga);
        //this.onChangeCampo('veiculo-origem', this.cotacao.VeiculoEspecialOri);
        //this.onChangeCampo('veiculo-destino', this.cotacao.VeiculoEspecialDes);

        // DM #5602 Combobox Tipo de Contêiner
        this.ObterTipoContainers(cotacaoRetorno);
        this.cnpjdropdown.modoBloqueado = true;
        this.cnpjdropdown.CNPJFiltro = cotacaoRetorno.cnpjCli;
        this.cnpjdropdown.cnpjsSelecionados = [];
        this.cnpjdropdown.cnpjsSelecionados.push(cotacaoRetorno.cnpjCli);
        this.cnpjdropdown.inscrEstadualSelecionada = cotacaoRetorno.ieCli;
        this.cnpjdropdown.razaoSocial = cotacaoRetorno.nomeCli;

        //this.cotacao.respOva = this.cotacao.responsaveisOva.find(x => x.descricao == cotacaoRetorno.responsavelEstufagem);

        this._dataService.getTiposPagadorFrete()
          .subscribe(lista => {
            //this.tiposPagadorFrete = lista;
            if (lista != null)
              this.descTipoPagadorFrete = lista.find(x => x.codigo == cotacaoRetorno.tipo).descricao;
        });

        if (this.duplicar) {
          // 5661: Cotação - duplicar cotação - dados copiados não selecionados
          this.limpaCamposQueNaoDevemSerCopiadosNoDuplicar();
          this.DisponibilizaCargaEspecial();
        }

        //COMENTADO PARA NÃO SUBIR EM PRODUÇÃO NA SPRINT1, DESCOMENTAR DEPOIS
        //this.uploadarquivos.configuraArquivos(this.cotacao.Arquivos);

        this.loading = false;
      },
      (err) => { },
      () => {
        // DM Redmine #5603 - Resolução do Bug Duplicar cotação sem o botão de "Solicitar Cotação"
        if (this.novaCotacao) { this.cotacao.statusCotacao = '' };
      });
  }

  limpaCamposQueNaoDevemSerCopiadosNoDuplicar(): void {
    this.cotacao.valMercadoria = 0;
    this.cotacao.pesoContainer = 0;
    this.cotacao.qtdeContainer = 0;
    this.cotacao.obsEcargo = '';
  }

  Solicitar() {

    //limpa mensagens de erros
    this._msgService.clearMessage();

    if (this.ValidarEnvioCotacao()) {

      if (this._appService.isAdmin()) {
        this._msgService.addMessage('Cotação', 'Usuário administrador não tem permissão para adicionar cotações. Essa funcionalidade é restrita a clientes.', 'warning');
        return;
      }

      //if ((this.tipoContainers != undefined) && (this.cotacao.idCategoriaContainer != undefined))
      //this.cotacao.tamanhoContainer = this.tipoContainers.find(x => x.Codigo == this.cotacao.idCategoriaContainer).Tamanho;
      if (this.municipioColeta != undefined)
        this.cotacao.idMunicColeta = this.municipioColeta.codigo;
      if (this.municipioEntrega != undefined)
        this.cotacao.idMunicEntrega = this.municipioEntrega.codigo;

      this.cotacao.IdUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
      this.cotacao.setFlagsEnvio();

      this.loadingSolicitar = true;

      //Responsável pelo carregamento na origem
      this.cotacao.flagEquipeIndicaCliOri = ((this.cotacao.respOva) && (this.cotacao.respOva.codigo == 1)) ? 'S' : 'N';
      this.cotacao.flagEquipePropriaOri = ((this.cotacao.respOva) && (this.cotacao.respOva.codigo == 2)) ? 'S' : 'N';
      this.cotacao.flagOvaEmbarcador = ((this.cotacao.respOva) && (this.cotacao.respOva.codigo == 3)) ? 'S' : 'N';

      //Responsável pelo carregamento no destino
      this.cotacao.flagEquipeIndicaCliDes = ((this.cotacao.respDesova) && (this.cotacao.respDesova.codigo == 1)) ? 'S' : 'N';
      this.cotacao.flagEquipePropriaDes = ((this.cotacao.respDesova) && (this.cotacao.respDesova.codigo == 2)) ? 'S' : 'N';
      this.cotacao.flagDesovaRecebedor = ((this.cotacao.respDesova) && (this.cotacao.respDesova.codigo == 3)) ? 'S' : 'N';


      this._cotacaoService.Solicitar(this.cotacao)
        .subscribe(result => {
          this.loadingSolicitar = false;
          if (result != null) {
            if (this.duplicar)
              this._analyticsService.RegistrarOperacao(AnalyticsTela.Cotacao, AnalyticsOperacao.Duplicacao);

            this.cotacao = result;
            this._msgService.addMessageRedirect('Cotação', 'Cotação Número ' + result.numCotacao + ' gerada com sucesso!', 'success');

            //COMENTADO PARA NÃO SUBIR EM PRODUÇÃO NA SPRINT1, DESCOMENTAR DEPOIS
            /*
            var alteracoesUpload = this.uploadarquivos.obterAlteracoesArquivos();

            if (alteracoesUpload.arquivosParaUpload.length > 0 || alteracoesUpload.arquivosParaExcluir.length > 0) {
              this.uploading = true;
              this._uploadService.salvarAlteracoesUpload(alteracoesUpload, 'cotacao', 'OGG', this.cotacao.numCotacao)
                .subscribe(result => {
                  this.uploading = false;
                  if (!result.IsValid){
                    this._msgService.addMessageRedirect('Cotação', 'Cotação Número ' + this.cotacao.numCotacao + ' gerada com sucesso!', 'success');
                  }
                  else {
                    this._msgService.addMessageRedirect('Cotação', 'Cotação ' + this.cotacao.numCotacao + ' gerada com sucesso, mas os anexos não foram incluídos. Por favor, tente anexar os documentos novamente!', 'warning', LogFrontFuncionalidade.Cotacao, Operacao.Inclusao);
                  }
                  this._router.navigate(['cotacao']);
                }, (errors) => {
                  this._msgService.addMessageRedirect('Cotação', 'Cotação ' + this.cotacao.numCotacao + ' gerada com sucesso, mas os anexos não foram incluídos. Por favor, tente anexar os documentos novamente!', 'warning', LogFrontFuncionalidade.Cotacao, Operacao.Inclusao);
                  this._router.navigate(['cotacao']);
                });
            }
            else
            */
              this._router.navigate(['cotacao']);
          }
          //else {
          //  this._msgService.addMessage('Cotação', "Erro ao solicitar cotação", "error");
          //}
        });
    }
  }

  salvarAlteracoesUpload(alteracoes: AlteracoesArquivoUpload){
    this.uploading = true;
    this._uploadService.salvarAlteracoesUpload(alteracoes, 'cotacao', 'OGG', this.cotacao.numCotacao)
      .subscribe(result => {
        this.uploading = false;
        if (!result.IsValid){
          this._msgService.addMessageRedirect('Cotação', 'Upload da cotação ' + this.cotacao.numCotacao + ' concluído com sucesso!', 'success');
          this.uploadarquivos.marcarAlteracoesComoSalvas();
        }
        else {
          this._msgService.addMessageRedirect('Cotação', 'Cotação ' + this.cotacao.numCotacao + ' gerada com sucesso, mas os anexos não foram incluídos. Por favor, tente anexar os documentos novamente!',
              'error', LogFrontFuncionalidade.Cotacao, Operacao.Upload, alteracoes);
        }
      });
  }

  AlterarStatus(status: string) {
    // TODO: Verificar porque ID da cotação está nulo mesmo quando atribuido no OnInit
    this.cotacao.Status = (status == 'A') ? 'ACEITA' : 'REJEITA';
    this.cotacao.IdUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
    this.cotacao.IdCotacao = this.cotacao.numCotacao;

    if ((this.cotacao.Status == 'REJEITA') && ((this.cotacao.obsComplementar == undefined) || (this.cotacao.obsComplementar.length == 0))) {
      this._msgService.addMessage('Cotação', 'Informe o motivo da recusa!', 'error', LogFrontFuncionalidade.Cotacao, Operacao.Alteracao, this.cotacao);
    }
    else {
      this._cotacaoService.AlterarCotacao(this.cotacao)
        .subscribe(result => {
          if (result != null) {
            this._msgService.addMessageRedirect('Cotação', 'Cotação atualizada com sucesso', 'success');
            this._router.navigate(['cotacao']);
          }
          // else {
          //   this._msgService.addMessage('Cotação', 'Erro ao atualizar cotação', 'error');
          // }
        });
    }
  }

  cssClass(step: number): string {
    if (step < this.currentStep)
      return 'completed';
    else
      if (step == this.currentStep)
        return 'active';
      else
        return 'disable';
  }

  iconClass(step: number): string {
    if (step < this.currentStep)
      return 'icon icon-check';
    else
      return 'icon icon-circle-o';
  }

  mudarStep(step: number) {

    if (!this.novaCotacao) {
      this.currentStep = step;
    }
    else {
      let validacao: boolean;

      //limpa mensagens de erros
      this._msgService.clearMessage();

      if (this.currentStep < step) {
        switch (this.currentStep) {
          case 1:
            validacao = this.ValidaStep1();
            // Redmine 5661
            if (this.cotacao.idMunicColeta) {
              this.municipioColeta = this.obtemMunicipioDaLista(this.cotacao.idMunicColeta);
            }

            if (this.cotacao.idMunicEntrega) {
              this.municipioEntrega = this.obtemMunicipioDaLista(this.cotacao.idMunicEntrega);
            }
            break;
          case 2:
            validacao = this.ValidaStep2();
            break;
          case 3:
            validacao = this.ValidaStep3();
            break;
        }
        if (validacao)
          this.currentStep = step;
      }
      else
        this.currentStep = step;
    }

    if (this.currentStep == step) {
      setTimeout(() => { this.topo.nativeElement.scrollIntoView(); });
    }
  }

  private obtemMunicipioDaLista(idMunicipio): Municipio {
    return this.municipios.find(x => x.codigo == idMunicipio);
  }

  public ObterTipoContainers(cotacao: Cotacao) {
    this.loadingContainers = true;
    this._sharedService.getTipoContainers(cotacao.idFamiliaProduto)
      .subscribe(items => {
        this.tipoContainers = [];
        items.forEach(item => {
          let tp: TipoContainer = new TipoContainer();
          this._sharedService.copyProperties(item, tp);
          this.tipoContainers.push(tp);
        });

        // DM #5602 Combobox Tipo de Contêiner
        this.idContainerSelecionado = cotacao.tipoDeContainer.codigo + '|' + cotacao.tipoDeContainer.tamanho;

        this.loadingContainers = false;
      });
  }

  public ObterTipoContainersPorIdFamiliaProduto(target: any = null) {
    var idFamiliaProduto = (target != undefined) ? target.value : null;
    this.flagOutrosProdutos = (idFamiliaProduto == 0); // 'OUTROS');
    //if (this.flagOutrosProdutos){
    // idFamiliaProduto = 0;
    //  this.cotacao.idFamiliaProduto = 0;
    //}

    this.bloqueiaCargaExcesso = true;
    this.bloqueiaCargaRefrigerada = true;
    this.cotacao.cargaOver = false;
    this.cotacao.cargaRefrigerada = false;
    this.LimpaCamposCargaRefrigerada();
    this.LimpaCamposCargaExcesso();

    if (this.novaCotacao && !this.flagOutrosProdutos)
      this.cotacao.descFamiliaProduto = '';

    this.loadingContainers = true;
    this._sharedService.getTipoContainers(idFamiliaProduto)
      .subscribe(items => {
        this.tipoContainers = [];
        items.forEach(item => {
          let tp: TipoContainer = new TipoContainer();
          this._sharedService.copyProperties(item, tp);
          this.tipoContainers.push(tp);
        });
        this.loadingContainers = false;
      });
  }
  /* >>>>>> Gylci 23/06 >>>>>> Busca comentada pois o cliente não garante a origem dos dados >>>>>>
  public ObterCargaPerigosa() {
    if (this.cotacao.cargaPerigosa) {
      this.loadingCargaPerigosa = true;
      this._sharedService.getCargaPerigosa()
        .subscribe(m => {
          this.cargasPerigosas = m;

          if (this.cargasPerigosas != undefined) {
            this.cargasPerigosas.forEach(element => {
              let item = this.classes.find(i => i.Descricao.trim().toUpperCase() == element.Classe.trim().toUpperCase());
              if (item == undefined) {
                let cl: Classe = new Classe();
                cl.Descricao = element.Classe;
                this.classes.push(cl);
              }
            });

            this.classes.sort((a: Classe, b: Classe) => {
              if (a.Descricao < b.Descricao) {
                return -1;
              } else if (a.Descricao > b.Descricao) {
                return 1;
              } else {
                return 0;
              }
            });
          }
          this.loadingCargaPerigosa = false;
        });
    }
  }

  public ObterCargaPerigosaPorClasse(classe: string) {
    this.cargasPerigosasByClass = this.cargasPerigosas.filter(x => x.Classe.trim().toUpperCase() == classe.trim().toUpperCase());

    this.cargasPerigosasByClass.sort((a: CargaIMO, b: CargaIMO) => {
      if (a.NumUNO < b.NumUNO) {
        return -1;
      } else if (a.NumUNO > b.NumUNO) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */

  public ObterMercadorias() {

    this.loadingMercadorias = true;
    this._sharedService.getMercadorias()
      .subscribe(m => {
        this.loadingMercadorias = false;
        this.mercadorias = m;
        this.mercadorias.push({codigo: 0, descricao: 'OUTROS' });
      });
  }

  public ObterPortos() {
    this._sharedService.getPortos()
      .subscribe(m => {
        this.portos = m;
      });
  }

  public ObterMunicipios() {
    this.loadingMunicipios = true;
    this._sharedService.getMunicipios()
      .subscribe(m => {
        this.municipios = m;
        this.loadingMunicipios = false;
      });
  }

  onChangeCampo(campo: string, valor: string) {
    switch (campo) {
      case 'embalagem':
        this.cotacao.tipoEmbalagemCarga = (valor != 'Outros') ? valor : '';
        break;

      case 'veiculo-origem':
        this.cotacao.VeiculoEspecialOri = (valor != 'Outros') ? valor : '';
        break;

      case 'veiculo-destino':
        this.cotacao.VeiculoEspecialDes = (valor != 'Outros') ? valor : '';
        break;
    }
  }

  ExibeBotoesAceite(): boolean {
    return ((this.cotacao.statusCotacao != undefined) && (this.cotacao.statusCotacao.toUpperCase() == 'AGUARDANDO APROVAÇÃO DO CLIENTE'));
  }

  ExibeBotaoSolicitar(): boolean {
    return ((this.cotacao.statusCotacao == undefined) || (this.cotacao.statusCotacao.toUpperCase() == ''));
  }

  containerTankSelecionado(): boolean{
    return this.idContainerSelecionado && this.idContainerSelecionado.startsWith('5|');
  }

  DisponibilizaCargaEspecial() {
    if (this.containerTankSelecionado()){
      this.cotacao.shipperOwnContainer = true;
      this.cotacao.oriPorto = true;
      this.cotacao.desPorto = true;
      this.bloqueiaModalPorta = true;
    }
    else{
      this.cotacao.modal = undefined;
      this.cotacao.shipperOwnContainer = false;
      this.bloqueiaModalPorta = false;
    }
    if (((this.tipoContainers != undefined) && (this.tipoContainers.length > 0)) || this.duplicar) {
      var tipocontainer: TipoContainer;
      if (this.duplicar)
        tipocontainer = this.cotacao.tipoDeContainer;
      else
        tipocontainer = this.tipoContainers.find(t => t.idContainer == this.idContainerSelecionado);

      if (tipocontainer && tipocontainer.desc_resumida) {
        if ((tipocontainer.desc_resumida.indexOf('OT') > 0) || (tipocontainer.desc_resumida.indexOf('OH') > 0) ||
          (tipocontainer.desc_resumida.indexOf('FR') > 0) || (tipocontainer.desc_resumida.indexOf('FH') > 0)) {
          this.bloqueiaCargaExcesso = false;
          this.bloqueiaCargaRefrigerada = true;
          if (!this.duplicar) {
            this.cotacao.cargaRefrigerada = false;
            this.cotacao.cargaOver = true;
            this.LimpaCamposCargaRefrigerada();
          }

          if ((tipocontainer.desc_resumida.indexOf('OT') > 0) || (tipocontainer.desc_resumida.indexOf('OH') > 0)) {
            this.bloqueiaOpenTop = true;
            if (!this.duplicar) {
              this.cotacao.CargaOverFrontal = 0;
              this.cotacao.CargaOverTraseiro = 0;
              this.cotacao.CargaOverDireita = 0;
              this.cotacao.CargaOverEsquerda = 0;
            }
          }
          else
            this.bloqueiaOpenTop = false;
        }
        else if ((tipocontainer.desc_resumida.indexOf('RF') > 0) || (tipocontainer.desc_resumida.indexOf('RH') > 0)) {
            this.bloqueiaCargaExcesso = true;
            this.bloqueiaCargaRefrigerada = false;
            if (!this.duplicar) {
              this.cotacao.cargaOver = false;
              this.cotacao.cargaRefrigerada = true;
              this.cotacao.tempCargaRefrigerada = undefined;
              this.LimpaCamposCargaExcesso();
            }
        }
        else {
          this.bloqueiaCargaExcesso = true;
          this.bloqueiaCargaRefrigerada = true;
          if (!this.duplicar) {
            this.cotacao.cargaOver = false;
            this.cotacao.cargaRefrigerada = false;
            this.LimpaCamposCargaRefrigerada();
            this.LimpaCamposCargaExcesso();
          }
        }
      }
    }
  }

  ValidarEnvioCotacao(): boolean {
    return this.ValidaStep1() && this.ValidaStep2() && this.ValidaStep3();
  }

  ValidaStep1(): boolean {
    let msgErro: string[] = [];

    if ((!this.cnpjdropdown.isUsuarioAdmin) && ((!this.cnpjdropdown.MaisDe1Cnpj))) {
      this.cotacao.cnpjCli = this.cnpjdropdown.cnpjsSelecionados.toString();
      this.cotacao.ieCli = this.cnpjdropdown.inscrEstadualSelecionada;
    }
    else {
      if (!this.cnpjdropdown.isUsuarioAdmin) {
        if ((this.cnpjdropdown.cnpjsSelecionados == undefined) || (this.cnpjdropdown.inscrEstadualSelecionada == undefined) ||
          (this.cnpjdropdown.inscrEstadualSelecionada == '')) {
          msgErro.push('Informe o CNPJ e Inscrição Estadual!');
        }
        else {
          this.cotacao.cnpjCli = this.cnpjdropdown.cnpjsSelecionados.toString();
          this.cotacao.ieCli = this.cnpjdropdown.inscrEstadualSelecionada;
        }
      }
      else {
        if ((this.cnpjdropdown.CNPJDigitado == undefined) || (this.cnpjdropdown.CNPJDigitado.trim() == '') ||
          (this.cnpjdropdown.inscrEstadualSelecionada == undefined) || (this.cnpjdropdown.inscrEstadualSelecionada == '')) {
          msgErro.push('Informe o CNPJ e Inscrição Estadual!');
        }
        else {
          this.cotacao.cnpjCli = this.cnpjdropdown.CNPJDigitado;
          this.cotacao.ieCli = this.cnpjdropdown.inscrEstadualSelecionada;
        }
      }
    }

    //Step 1 - Operação Comercial
    if ((this.cotacao.tipoOperacaoComercial == undefined) || (this.cotacao.tipoOperacaoComercial == '')) {
      msgErro.push('Selecione o tipo de Operação Comercial!');
    }

    //Step 1 - Pagador do Frete
    if ((this.cotacao.tipo == undefined) || (this.cotacao.tipo == '')) {
      msgErro.push('Selecione o Pagador do Frete!');
    }

    //Step 1 - Produto / Mercadoria
    if ( (this.cotacao.idFamiliaProduto == undefined) ||
         ((this.cotacao.idFamiliaProduto == 0) && ((this.cotacao.descFamiliaProduto == undefined) || (this.cotacao.descFamiliaProduto == '')) ) ) {
      msgErro.push('Selecione um Produto! Se \'Outros\', detalhe!');
    }
    else {
      if (this.cotacao.idFamiliaProduto == 0)
        this.cotacao.outrosProdutos = this.cotacao.descFamiliaProduto;
    }

    //Step 1 - Tipo do contêiner
    //if ((this.cotacao.idCategoriaContainer == undefined) || (this.cotacao.idCategoriaContainer == 0)) {
    if ((this.idContainerSelecionado == '') || (this.idContainerSelecionado == '0')) {
      msgErro.push('Selecione o Tipo de Contêiner!');
    }
    else {
      var tipocontainer: TipoContainer;
      if ((this.tipoContainers != undefined) && (this.tipoContainers.length > 0)) {
        tipocontainer = this.tipoContainers.find(t => t.idContainer == this.idContainerSelecionado);
        this.cotacao.idCategoriaContainer = tipocontainer.codigo;
        this.cotacao.tamanhoContainer = tipocontainer.tamanho;

        if (tipocontainer && tipocontainer.desc_resumida) {
          /*
          if (((tipocontainer.desc_resumida.indexOf('OT') > 0) || (tipocontainer.desc_resumida.indexOf('OH') > 0) ||
            (tipocontainer.desc_resumida.indexOf('FR') > 0) || (tipocontainer.desc_resumida.indexOf('FH') > 0)) &&
            (!this.cotacao.cargaOver)) {
            msgErro.push('Para esse Tipo de Contêiner é obrigatória a seleção da Carga em Excesso!');
          }
          */
          if ((tipocontainer.desc_resumida.indexOf('OT') < 0) && (tipocontainer.desc_resumida.indexOf('OH') < 0) &&
            (tipocontainer.desc_resumida.indexOf('FR') < 0) && (tipocontainer.desc_resumida.indexOf('FH') < 0) &&
            (this.cotacao.cargaOver)) {
            msgErro.push('Para Carga em Excesso, o Tipo de Contêiner deve ser OT, OH, FR ou FH!');
          }
          if (((tipocontainer.desc_resumida.indexOf('RF') > 0) || (tipocontainer.desc_resumida.indexOf('RH') > 0)) &&
            (!this.cotacao.cargaRefrigerada)) {
            msgErro.push('Para esse Tipo de Contêiner é obrigatória a seleção da Carga Refrigerada!');
          }
          if ((tipocontainer.desc_resumida.indexOf('RF') < 0) && (tipocontainer.desc_resumida.indexOf('RH') < 0) &&
            (this.cotacao.cargaRefrigerada)) {
            msgErro.push('Para Carga Refrigerada, o Tipo de Contêiner deve ser RF ou RH!');
          }
        }
      }
    }

    //Step 1 - Embalagem de Cargas
    if ((this.cotacao.tipoEmbalagemCarga == undefined) || (this.cotacao.tipoEmbalagemCarga.trim() == '')) {
      if (this.cotacao.flagEmbalagemEOutros)
        msgErro.push('Detalhe o Tipo de Embalagem da Carga - Outros!');
      else
        msgErro.push('Selecione o Tipo de Embalagem da Carga!');
    }

    //Step 1 - Temperatura (se flagCargaRefrigerada =='S')
    if ((this.cotacao.flagCargaRefrigerada == 'S') &&
      ((this.cotacao.tempCargaRefrigerada == undefined))) { //|| (this.cotacao.tempCargaRefrigerada == 0))) {
      msgErro.push('Informe a Temperatura!');
    }
    else {
      // Verifica se é inteiro
      //if ((this.cotacao.tempCargaRefrigerada != undefined) && (!(this.cotacao.tempCargaRefrigerada % 1 === 0)))
      if (!this._sharedService.validaInteiro(this.cotacao.tempCargaRefrigerada))
        msgErro.push('A Temperatura deve ser um número inteiro!');
      else if (this.cotacao.tempCargaRefrigerada < -100 || this.cotacao.tempCargaRefrigerada > 100)
        msgErro.push('A Temperatura deve estar entre -100°C e 100°C!');
    }

    //Step1 - Observação Carga Refrigerada
    if ((this.cotacao.obsCargaRefrigerada != undefined) && (this.cotacao.obsCargaRefrigerada.length > 255)) {
      msgErro.push('Observação de carga refrigerada deve conter no máximo 255 caracteres!');
    }

    //Step 1 - Excesso de Carga (se flagCargaOver =='S')
    if ((this.cotacao.flagCargaOver == 'S') &&
      ((this.cotacao.CargaOverAltura == undefined) || (this.cotacao.CargaOverAltura == 0)) &&
      ((this.cotacao.CargaOverEsquerda == undefined) || (this.cotacao.CargaOverEsquerda == 0)) &&
      ((this.cotacao.CargaOverDireita == undefined) || (this.cotacao.CargaOverDireita == 0)) &&
      ((this.cotacao.CargaOverTraseiro == undefined) || (this.cotacao.CargaOverTraseiro == 0)) &&
      ((this.cotacao.CargaOverFrontal == undefined) || (this.cotacao.CargaOverFrontal == 0))) {
      msgErro.push('Informe o Excesso de Carga!');
    }
    else {
      // Carga com excesso, só é possível quando o contêiner selecionado é dos tipos Flat Rack ou Open Top,
      // sendo que o Open Top só pode ter excesso de altura.

      if (tipocontainer && tipocontainer.desc_resumida) {
        if (((tipocontainer.desc_resumida.indexOf('OT') > 0) || (tipocontainer.desc_resumida.indexOf('OH') > 0)) &&
            (this.cotacao.flagCargaOver == 'S') &&
            (((this.cotacao.CargaOverAltura == undefined) || (this.cotacao.CargaOverAltura == 0)) ||
            !((this.cotacao.CargaOverEsquerda == undefined) || (this.cotacao.CargaOverEsquerda == 0)) ||
            !((this.cotacao.CargaOverDireita == undefined) || (this.cotacao.CargaOverDireita == 0)) ||
            !((this.cotacao.CargaOverTraseiro == undefined) || (this.cotacao.CargaOverTraseiro == 0)) ||
            !((this.cotacao.CargaOverFrontal == undefined) || (this.cotacao.CargaOverFrontal == 0)))) {
            msgErro.push('Para contêineres Open Top o excesso de carga deve ser apenas de altura!');
        }
      }

      if (!this._sharedService.validaInteiro(this.cotacao.CargaOverAltura)) {
        //console.log('erro altura');
        msgErro.push('O excesso de Altura deve ser um número inteiro!');
      }
      //else
      //  console.log('altura ok');

      if (!this._sharedService.validaInteiro(this.cotacao.CargaOverEsquerda)){
        //console.log('erro esquerda');
        msgErro.push('O excesso à Esquerda deve ser um número inteiro!');
      }
      //else
      //  console.log('esquerda ok');

      if (!this._sharedService.validaInteiro(this.cotacao.CargaOverDireita)){
      //  console.log('erro direita');
        msgErro.push('O excesso à Direta deve ser um número inteiro!');
      }
      //else
      //  console.log('direita ok');

      if (!this._sharedService.validaInteiro(this.cotacao.CargaOverFrontal)){
        //console.log('erro frontal');
        msgErro.push('O excesso Frontal deve ser um número inteiro!');
      }
      //else
      //  console.log('frontal ok');

      if (!this._sharedService.validaInteiro(this.cotacao.CargaOverTraseiro)){
      //  console.log('erro traseiro');
        msgErro.push('O excesso Traseiro deve ser um número inteiro!');
      }
      //else
      //  console.log('traseiro ok');
    }

    //Step1 - Observação Carga Excesso
    if ((this.cotacao.obsCargaOver != undefined) && (this.cotacao.obsCargaOver.length > 255)) {
      msgErro.push('Observação de carga excesso deve conter no máximo 255 caracteres!');
    }

    //Step 1 - Classe (se flagCargaPerigosa =='S')
    if (this.cotacao.flagCargaPerigosa == 'S') {
      if (!this.cotacao.classe)
        msgErro.push('Informe a Classe!');
      if (!this.cotacao.numeroUNO)
        msgErro.push('Informe o Número UNO!');
      if (!this.cotacao.sequencia)
        msgErro.push('Informe a Sequência!');
      if (!this.cotacao.properShippingName)
        msgErro.push('Informe o PSN!');
    }

    //Step1 - Observação Carga Perigosa
    if ((this.cotacao.obsCargaPerigosa) && (this.cotacao.obsCargaPerigosa.length > 255)) {
      msgErro.push('Observação de carga perigosa deve conter no máximo 255 caracteres!');
    }

    //Step 1 - Carga Refrigerada e Carga com Excesso não podem ser selecionadas pelo cliente na mesma cotação ou booking.
    if  ((this.cotacao.flagCargaOver == 'S') && (this.cotacao.flagCargaRefrigerada == 'S')) {
      msgErro.push('Carga Refrigerada e Carga com Excesso não podem ser selecionadas simultaneamente na mesma cotação!');
    }

    //Step 1 - Valor da Mercadoria
    if ((this.cotacao.valMercadoria == undefined) || (this.cotacao.valMercadoria == 0)) {
      msgErro.push('Informe o Valor da Mercadoria!');
    }

    //Step 1 - Peso da Carga por Contêiner
    if ((this.cotacao.pesoContainer == undefined) || (this.cotacao.pesoContainer == 0)) {
      msgErro.push('Informe o Peso da Carga por Contêiner!');
    }

    //Step 1 - Quantidade de Contêineres
    if ((this.cotacao.qtdeContainer == undefined) || (this.cotacao.qtdeContainer == 0)) {
      msgErro.push('Informe a Quantidade de Contêineres Mensal!');
    }
    else {
      if (this.cotacao.qtdeContainer > 300)
        msgErro.push('A Quantidade de Contêineres Mensal não pode ser maior que 300!');

      if (!this._sharedService.validaInteiro(this.cotacao.qtdeContainer) || this.cotacao.qtdeContainer <= 0)
        msgErro.push('A Quantidade de Contêineres Mensal deve ser um número inteiro maior que 0 (zero)!');
    }

    //Step 1 - Periodicidade
    if ((this.cotacao.periodicidade == undefined) || (this.cotacao.periodicidade == '')) {
      msgErro.push('Selecione a Periodicidade!');
    }

    //Step 1 - Modal Origem
    if (this.cotacao.oriPorto == undefined) {
      msgErro.push('Selecione o Modal de Origem!');
    }

    //Step 1 - Modal Destino
    if (this.cotacao.desPorto == undefined) {
      msgErro.push('Selecione o Modal de Destino!');
    }

    //Se houve erro, exibe mensagens
    if (msgErro.length > 0) {
      this._msgService.addMessageArray('Cotação', msgErro, 'error', LogFrontFuncionalidade.Cotacao, Operacao.Inclusao, this.cotacao);
    }

    return (msgErro.length == 0);

  }

  ValidaStep2(): boolean {
    let msgErro: string[] = [];

    //Step 2 - Porto de Origem
    if ((this.cotacao.oriPorto) && ((this.cotacao.idPortoOri == undefined) || (this.cotacao.idPortoOri == 0))) {
      msgErro.push('Selecione o Porto de Origem!');
    }

    if (!this.cotacao.oriPorto) {
      //Step 2 - Cidade de Origem
      if (this.municipioColeta == undefined) {
        msgErro.push('Informe o Município para Coleta!');
      }

      //Step 2 - Estrutura para Ova
      if ((this.cotacao.flagEstruturaOvaContainer == undefined) || (this.cotacao.flagEstruturaOvaContainer == '')) {
        msgErro.push('Informe se Possui Estrutura para Estufagem do Contêiner!');
      }

      //Step 2 - Veículo Especial Origem
      if ((this.cotacao.flagEstruturaOvaContainer == 'N') &&
        ((this.cotacao.VeiculoEspecialOri == '') || (this.cotacao.VeiculoEspecialOri == undefined))) {
        if (!this.cotacao.flagVeiculoDesEOutros)
          msgErro.push('Informe Veículo Especial de Origem!');
        else
          msgErro.push('Informe Detalhes do Veículo Especial de Origem!');
      }
      else {
        if ((this.cotacao.flagEstruturaOvaContainer == 'N') && (this.cotacao.VeiculoEspecialOri != '') &&
            ((this.cotacao.metrosCubicosOri == undefined) || (this.cotacao.metrosCubicosOri == 0))) {
            msgErro.push('Informe os metros cúbicos!');
        }
        else {
          // Verifica se é inteiro
          if ((this.cotacao.flagEstruturaOvaContainer == 'N') && (!(this.cotacao.metrosCubicosOri % 1 === 0)))
            msgErro.push('Os metros cúbicos devem ser um número inteiro!');
        }
      }

      //Step 2 - Estufagem em Terminal
      if ((this.cotacao.flagEstruturaOvaContainer == 'N') && ((this.cotacao.flagOvaTerminal == undefined) || (this.cotacao.flagOvaTerminal == ''))) {
        msgErro.push('Informe se Estufagem em Terminal!');
      }

      //Step 2 - Tempo Estimado para Estufagem
      if ((this.cotacao.tempoHrEstimadoOva == undefined) || (this.cotacao.tempoHrEstimadoOva == 0)) {
        msgErro.push('Informe o Tempo Estimado para Estufagem!');
      }

      //Step 2 - Responsável pela Estufagem na Origem
      //if (this.cotacao.responsavelEstufagem == undefined) {
      if (this.cotacao.respOva == undefined) {
        msgErro.push('Selecione o Responsável pela Estufagem na Origem!');
      }
      else {
        this.cotacao.equipeIndicaCliOri = (this.cotacao.responsavelEstufagem == 'Aliança com equipe local indicada pelo cliente');
      }

      //Step 2 - Quantidade de Ajudantes
      if ((this.cotacao.responsavelEstufagem != undefined) && (this.cotacao.responsavelEstufagem == 'Aliança com equipe própria') &&
        ((this.cotacao.qtdeAjudanteOva == undefined) || (this.cotacao.qtdeAjudanteOva == 0))) {
        msgErro.push('Informe a Quantidade de Ajudantes!');
      }

      //Step 2 - Material de Peação
      if ((this.cotacao.responsavelEstufagem != undefined) && (this.cotacao.responsavelEstufagem == 'Aliança com equipe própria') &&
        ((this.cotacao.flagMaterialPeacao == undefined) || (this.cotacao.flagMaterialPeacao == ''))) {
        msgErro.push('Informe se Material de Peação!');
      }

      //Step 2 - Descrição do Material de Peação
      if ((this.cotacao.flagMaterialPeacao == 'S') &&
        ((this.cotacao.descMaterialPeacao == undefined) || (this.cotacao.descMaterialPeacao.trim() == ''))) {
        msgErro.push('Detalhe o Material de Peação!');
      }

      //Step 2 - Custo Precificação
      // TODO: DM - conforme solicitação da Mari, devido campo valCustoPrecificacaoOri ter sido comentado, foi necessário também comentar a validação do mesmo
      // if ((this.cotacao.responsavelEstufagem != undefined) && (this.cotacao.responsavelEstufagem == 'Aliança com equipe local indicada pelo cliente') &&
      //   ((this.cotacao.valCustoPrecificacaoOri == undefined) || (this.cotacao.valCustoPrecificacaoOri == 0))) {
      //   msgErro.push('Informe o Custo Considerado para Precificação!');
      // }

      //Step 2 - Valor da Equipe indicada pelo cliente
      //if ((this.cotacao.flagEquipeIndicaCliOri != undefined) && (this.cotacao.flagEquipeIndicaCliOri == 'S') &&
      if ((this.cotacao.responsavelEstufagem != undefined) && (this.cotacao.responsavelEstufagem == 'Aliança com equipe local indicada pelo cliente') &&
        ((this.cotacao.valEquipeServEspOri == undefined) || (this.cotacao.valEquipeServEspOri == 0))) {
        msgErro.push('Informe o Valor da Equipe indicada pelo cliente!');
      }
    }

    //Se houve erro, exibe mensagens
    if (msgErro.length > 0) {
      this._msgService.addMessageArray('Cotação', msgErro, 'error', LogFrontFuncionalidade.Cotacao, Operacao.Inclusao, this.cotacao);
    }
    else {
      // se for origem PortO, atribui município do porto no componente cotação
      if (this.cotacao.oriPorto) {
        var portoSelecionado: Porto;
        portoSelecionado = this.portos.find(p => p.codigo == this.cotacao.idPortoOri);

        var municipioSelecionado: Municipio;
        municipioSelecionado = this.municipios.find(x => x.descricao.trim().toUpperCase() == portoSelecionado.municipio.trim().toUpperCase() &&
          x.uf.trim().toUpperCase() == portoSelecionado.uF.trim().toUpperCase());
        this.cotacao.idMunicColeta = municipioSelecionado.codigo;
        this.cotacao.idMunicOri = municipioSelecionado.codigo;
        this.cotacao.municOri = municipioSelecionado.descricao;
        this.cotacao.municColeta = municipioSelecionado.descricao;
        this.cotacao.ufColeta = municipioSelecionado.uf;
        this.cotacao.ufMunicOri = municipioSelecionado.uf;
      }
    }

    return (msgErro.length == 0);
  }

  ValidaStep3(): boolean {
    let msgErro: string[] = [];

    // Step 3 - Porto de Destino
    if ((this.cotacao.desPorto) && ((this.cotacao.idPortoDes == undefined) || (this.cotacao.idPortoDes == 0))) {
      msgErro.push('Selecione o Porto de Destino!');
    }

    if (!this.cotacao.desPorto) {
      //Step 3 - Cidade de Destino
      if (this.municipioEntrega == undefined) {
        msgErro.push('Informe o Município para Entrega!');
      }

      if (this.municipioColeta == this.municipioEntrega){
        msgErro.push('O Município para Entrega não pode ser o mesmo da Coleta!');
      }

      //Step 3 - Estrutura para Desova
      if ((this.cotacao.flagEstruturaDesovaContainer == undefined) || (this.cotacao.flagEstruturaDesovaContainer == '')) {
        msgErro.push('Informe se Possui Estrutura para Desova do Contêiner!');
      }

      //Step 3 - Veículo Especial de Destino
      if ((this.cotacao.flagEstruturaDesovaContainer == 'N') &&
        ((this.cotacao.VeiculoEspecialDes == '') || (this.cotacao.VeiculoEspecialDes == undefined))) {
        if (!this.cotacao.flagVeiculoDesEOutros)
          msgErro.push('Informe Veículo Especial de Destino!');
        else
          msgErro.push('Informe Detalhes do Veículo Especial de Destino!');
      }
      else {
        if ((this.cotacao.flagEstruturaDesovaContainer == 'N') && (this.cotacao.VeiculoEspecialDes != '') &&
            ((this.cotacao.metrosCubicosDes == undefined) || (this.cotacao.metrosCubicosDes == 0))) {
            msgErro.push('Informe os metros cúbicos!');
        }
        else {
          // Verifica se é inteiro
          if ((this.cotacao.flagEstruturaDesovaContainer == 'N') && (!(this.cotacao.metrosCubicosDes % 1 === 0)))
            msgErro.push('Os metros cúbicos devem ser um número inteiro!');
        }
      }

      //Step 3 - Responsável pela Desova no Destino
      if ((this.cotacao.respEstufagemDes == undefined) || (this.cotacao.respEstufagemDes == '')) {
        msgErro.push('Selecione o Responsável pela Desova no Destino!');
      }
      else {
        this.cotacao.equipeIndicaCliDes = (this.cotacao.respEstufagemDes == 'Aliança com equipe local indicada pelo cliente');
      }

      //Step 3 - Tempo Estimado para Desova
      if ((this.cotacao.tempoHrEstimadodesova == undefined) || (this.cotacao.tempoHrEstimadodesova == 0)) {
        msgErro.push('Informe o Tempo Estimado para Desova!');
      }

      //Step 3 - Quantidade de Ajudantes
      if ((this.cotacao.respEstufagemDes != undefined) && (this.cotacao.respEstufagemDes != '') &&
        (this.cotacao.respEstufagemDes == 'Aliança com equipe própria') &&
        ((this.cotacao.qtdeAjudantedesova == undefined) || (this.cotacao.qtdeAjudantedesova == 0))) {
        msgErro.push('Informe a Quantidade de Ajudantes!');
      }

      //Step 3 - Material Especial para Desova
      if ((this.cotacao.respEstufagemDes != undefined) && (this.cotacao.respEstufagemDes != '') &&
        (this.cotacao.respEstufagemDes == 'Aliança com equipe própria') &&
        ((this.cotacao.flagMaterialEspecialDesova == undefined) || (this.cotacao.flagMaterialEspecialDesova == ''))) {
        msgErro.push('Informe se Material Especial para Desova !');
      }

      //Step 3 - Descrição do Material Especial para Desova
      if ((this.cotacao.flagMaterialEspecialDesova == 'S') &&
        ((this.cotacao.materialEspecialDesova == undefined) || (this.cotacao.materialEspecialDesova.trim() == ''))) {
        msgErro.push('Detalhe o Material Especial para Desova!');
      }

      //Step 3 - Custo Precificação
      // TODO: DM - conforme solicitação da Mari, devido campo valCustoPrecificacaoOri ter sido comentado, foi necessário também comentar a validação do mesmo
      // if ((this.cotacao.respEstufagemDes != undefined) && (this.cotacao.respEstufagemDes != '') &&
      //   (this.cotacao.respEstufagemDes == 'Aliança com equipe local indicada pelo cliente') &&
      //   ((this.cotacao.valCustoPrecificacaoDes == undefined) || (this.cotacao.valCustoPrecificacaoDes == 0))) {
      //   msgErro.push('Informe o Custo Considerado para Precificação!');
      // }

      //Step 3 - Valor da Equipe indicada pelo cliente
      //if ((this.cotacao.flagEquipeIndicaCliDes != undefined) && (this.cotacao.flagEquipeIndicaCliDes != "") &&
      //(this.cotacao.flagEquipeIndicaCliDes == 'S') &&
       if ((this.cotacao.respEstufagemDes != undefined) && (this.cotacao.respEstufagemDes != '') &&
        (this.cotacao.respEstufagemDes == 'Aliança com equipe local indicada pelo cliente') &&
        ((this.cotacao.valEquipeServEspDes == undefined) || (this.cotacao.valEquipeServEspDes == 0))) {
        msgErro.push('Informe o Valor da Equipe indicada pelo cliente!');
      }
    }

    //Step3 - Observação Geral
    if ((this.cotacao.obsGeral != undefined) && (this.cotacao.obsGeral.length > 255)) {
      msgErro.push('Informações Complementares deve conter no máximo 255 caracteres!');
    }

    //Step3 - Observação Complementar
    if ((this.cotacao.obsComplementar != undefined) && (this.cotacao.obsComplementar.length > 255)) {
      msgErro.push('Observações Aceite/Rejeição deve conter no máximo 255 caracteres!');
    }

    //Se houve erro, exibe mensagens
    if (msgErro.length > 0) {
      this._msgService.addMessageArray('Cotação', msgErro, 'error', LogFrontFuncionalidade.Cotacao, Operacao.Inclusao, this.cotacao);
    }
    else {
      //se for destino PortO, atribui município do porto no componente cotação
      if (this.cotacao.desPorto) {
        var portoSelecionado: Porto;
        portoSelecionado = this.portos.find(p => p.codigo == this.cotacao.idPortoDes);

        var municipioSelecionado: Municipio;
        municipioSelecionado = this.municipios.find(x => x.descricao.trim().toUpperCase() == portoSelecionado.municipio.trim().toUpperCase() &&
          x.uf.trim().toUpperCase() == portoSelecionado.uF.trim().toUpperCase());
        this.cotacao.idMunicEntrega = municipioSelecionado.codigo;
        this.cotacao.idMunicDes = municipioSelecionado.codigo;
        this.cotacao.municDes = municipioSelecionado.descricao;
        this.cotacao.municEntrega = municipioSelecionado.descricao;
        this.cotacao.ufEntrega = municipioSelecionado.uf;
        this.cotacao.ufMunicDes = municipioSelecionado.uf;
      }
    }

    return (msgErro.length == 0);
  }

  LimpaCamposCargaRefrigerada() {
    if (!this.cotacao.cargaRefrigerada) {
      this.cotacao.tempCargaRefrigerada = undefined; // 0;
      this.cotacao.obsCargaRefrigerada = '';
      this.cotacao.gensetOri = false;
      this.cotacao.gensetDes = false;
    }
  }

  LimpaCamposCargaExcesso() {
    if (!this.cotacao.cargaOver) {
      this.cotacao.CargaOverAltura = 0;
      this.cotacao.CargaOverEsquerda = 0;
      this.cotacao.CargaOverDireita = 0;
      this.cotacao.CargaOverTraseiro = 0;
      this.cotacao.CargaOverFrontal = 0;
      this.cotacao.obsCargaOver = '';
    }
  }

  LimpaCamposCargaPerigosa() {
    if (!this.cotacao.cargaPerigosa) {
      this.cotacao.classe = '';
      this.cotacao.numeroUNO = '';
      this.cotacao.sequencia = '';
      this.cotacao.properShippingName = '';
      this.cotacao.obsCargaPerigosa = '';
    }
  }

  AlterouCamposEstruturaOva() {
    if (this.cotacao.estruturaOvaContainer){
      this.cotacao.veiculoOrigem = '';
      this.cotacao.metrosCubicosOri = 0;
      this.cotacao.ovaTerminal = false;
    }
    else
      this.cotacao.ovaTerminal = true;
  }

  get bloqueiaEstufagemEmTerminal(): boolean {
    return !this.cotacao.estruturaOvaContainer;
  }

  AlterouCamposEstruturaDesova() {
    if (this.cotacao.estruturaDesovaContainer){
      this.cotacao.veiculoDestino = '';
      this.cotacao.metrosCubicosDes = 0;
      this.cotacao.desovaTerminal = false;
    }
    else
      this.cotacao.desovaTerminal = true;
  }

  get bloqueiaDesovaEmTerminal(): boolean {
    return !this.cotacao.estruturaDesovaContainer;
  }

  LimpaCamposRespOva() {
    //  if (this.cotacao.responsavelEstufagem != 'Aliança com equipe local indicada pelo cliente') {
    //      this.cotacao.valCustoPrecificacaoOri = 0;
    //  }

    if (this.cotacao.responsavelEstufagem != 'Aliança com equipe própria') {
        this.cotacao.qtdeAjudanteOva = 0;
        this.cotacao.descMaterialPeacao = '';
        this.cotacao.materialPeacao = undefined;
        this.cotacao.flagMaterialPeacao = '';
    }

    if (!this.cotacao.materialPeacao)
      this.cotacao.descMaterialPeacao = '';
  }

  LimpaCamposPeacao() {
    this.cotacao.descMaterialPeacao = '';
  }

  LimpaEquipeIndCliOri() {
    if (!this.cotacao.equipeIndicaCliOri)
      this.cotacao.valEquipeServEspOri = 0;
  }

  LimpaCamposRespDesova() {
    // if (this.cotacao.responsavelEstufagem != 'Aliança com equipe local indicada pelo cliente') {
    //     this.cotacao.valCustoPrecificacaoOri = 0;
    // }

    if (this.cotacao.respEstufagemDes != 'Aliança com equipe própria') {
        this.cotacao.qtdeAjudantedesova = 0;
        this.cotacao.flagMaterialEspecialDesova = '';
        this.cotacao.materialEspecialDesovaFlag = undefined;
        this.cotacao.materialEspecialDesova = '';
    }

    if (!this.cotacao.materialEspecialDesovaFlag)
      this.cotacao.materialEspecialDesova = '';
  }

  LimpaCamposMaterialEspecial() {
    this.cotacao.materialEspecialDesova = '';
  }

  LimpaEquipeIndCliDes() {
    if (!this.cotacao.equipeIndicaCliDes)
      this.cotacao.valEquipeServEspDes = 0;
  }
}
